@import "~@/erp/styles/variables/variables.scss";















































































































































































































.detail-order {
  padding: 10px;
  border-left: 1px solid #d7d7d7;
  .box-style {
    display: inline-block;
    width: 250px;
    height: 80px;
    border: 1px solid #d8d8d8;
    vertical-align: middle;
    padding: 15px 0 0 20px;
  }
}
::v-deep {
  .el-table {
    height: 620px !important;
  }
}
.table-fixed {
  height: 680px !important;
}
