@import "~@/erp/styles/variables/variables.scss";





















































































































































.detail-product {
  min-height: 700px;
  padding: 10px;
  border-left: 1px solid #d7d7d7;
}
