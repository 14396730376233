@import "~@/erp/styles/variables/variables.scss";











































































































































































.public-container {
  overflow-y: auto;
  margin-bottom: 100px;
}
.right-fixed {
  position: absolute;
  top: 0;
  right: 69px;
}
::v-deep {
  .el-tabs__header {
    margin: 0;
  }
}
