/*! 2025-2-14 17:56:46 */
[data-v-b7be6b6a]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.his-warp .mask[data-v-b7be6b6a]{position:fixed;left:0;top:0;width:100%;height:100%;opacity:0.5;background:#000000;z-index:1998}.his-warp[data-v-b7be6b6a]  .el-dialog{position:relative;z-index:1999}.his-warp[data-v-b7be6b6a]  .el-dialog .el-dialog__body{max-height:500px;overflow-y:auto}

[data-v-760d3f94]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.detail-msg[data-v-760d3f94]{padding:20px;border-left:1px solid #d7d7d7;min-height:700px}.base-box label[data-v-760d3f94]{color:#666}.base-box[data-v-760d3f94] .el-col{height:32px}.base-box .col-span[data-v-760d3f94]{color:#000}.fileAnnex-span[data-v-760d3f94]{display:inline-block;width:15px;height:15px;line-height:15px;text-align:center;background:#9e9e9e;color:#fff;border-radius:50%;margin-left:5px;font-size:12px}.w90[data-v-760d3f94]{width:90%}.table-pay[data-v-760d3f94]{width:80%}.table-pay th[data-v-760d3f94]{font-weight:normal}.table-pay td[data-v-760d3f94]{height:36px}.prod-code[data-v-760d3f94]{color:#409eff;text-decoration:underline;cursor:pointer}.his-account-period[data-v-760d3f94]{font-weight:normal;margin-left:10px;color:#409eff;cursor:pointer}

[data-v-6f07e6f7]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.detail-product[data-v-6f07e6f7]{min-height:700px;padding:10px;border-left:1px solid #d7d7d7}

[data-v-3cc6dd26]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.detail-order[data-v-3cc6dd26]{padding:10px;border-left:1px solid #d7d7d7}.detail-order .box-style[data-v-3cc6dd26]{display:inline-block;width:250px;height:80px;border:1px solid #d8d8d8;vertical-align:middle;padding:15px 0 0 20px}[data-v-3cc6dd26] .el-table{height:620px !important}.table-fixed[data-v-3cc6dd26]{height:680px !important}

[data-v-2c715ab1]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.public-container[data-v-2c715ab1]{overflow-y:auto;margin-bottom:100px}.right-fixed[data-v-2c715ab1]{position:absolute;top:0;right:69px}[data-v-2c715ab1] .el-tabs__header{margin:0}


/*# sourceMappingURL=chunk-259b72a6.21e7557b.css.map*/