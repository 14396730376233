@import "~@/erp/styles/variables/variables.scss";




















































































































.his-warp{
  .mask{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000000;
    z-index:1998;
  }
  ::v-deep .el-dialog {
    position:relative;
    z-index:1999;
    .el-dialog__body {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}

